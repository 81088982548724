import React from 'react';

export const Procesos = () =>{
    return (
        <div>
                  <p class="px-5 mb-5 pb-3 lead grey-text text-justify" style={{color:'grey'}}>En la búsqueda de una cultura de innovación y mejora continua en las áreas de trabajo cotidianas en las organizaciones,
                    te apoyamos a cambiar hábitos inadecuados por las prácticas de valor que permitan a quienes son parte de
                    la organización:
                <br></br>
                         <br></br> A) La adecuada Ejecución y Gestión de sus funciones en el cumplimiento de las prácticas a través de los
                procesos y estructuras de trabajo implementadas en la organización.
                <br></br>
                         <br></br> B) Un estilo de liderazgo creativo, equipos de trabajo participativos e innovadores en la toma de decisiones.
                <br></br>
                  <br></br> <strong>OFERTA DE VALOR.</strong> Un modelo de servicio que brinda soluciones Ágiles e Innovadoras a Proyectos de Tecnologías
                de Información en el desarrollo de sus mejores prácticas.
                        </p>
            
        </div>
    );
}



  export const Implementacion = ()=>{

return(
  <>
  <h5 class="font-weight-bold mt-3">Implementación de Procesos.</h5>
  <p> * Assessment prácticas actuales de trabajo, Áreas de Oportunidad y su impacto en la empresa.</p>
  <p> * Documentación y Mapeo de Procesos.</p>
  <p> * Desarrollo de Flujos de Trabajo.</p>
  <p> * Matrices de Responsabilidades RACI (Responsable, Aprobador, Consultado, Informado).</p>
  <p> * Desarrollo de Formatos de Trabajo.</p>
  <p> * Identificación de Controles Alineados al Negocio.</p>
  <p> * Definición de Indicadores de Trabajo Alineados a Objetivos Estratégicos.</p>
  <p> * Documentación de Entregables Generados.</p>
  <p> * </p>

  </>

);

}

