import React from 'react';
import IconCross from './../Icons/IconCross';
import './Content.scss';
import ReactPlayer from 'react-player'
import { Player } from 'video-react';
import { ReactVideo } from "reactjs-media";

const Content = ({ movie, onClose }) => (
  <div className="content">
    <div className="content__background">
      <div className="content__background__shadow" />
      <div
        className="content__background__image"
        style={{ 'background-image': `url(${movie.imageBg})` }}
      />
    </div>
    
    <div className="content__background">
      <div className="content__background__shadow" />
      <ReactPlayer
        className="content__background__image"
        style={{ 'background-image': `url(${movie.url})` }} 
         url={movie.url}
        playing
        width='70%'
        height='100%'
        controls={false}
        
      />
       {/* <ReactVideo
     
        src={movie.src}
        playing
        width='30%'
        height='50%'
        controls={false}
        
       /> */}
    </div>

    <div className="content__area">
      <div className="content__area__container">
        <div className="content__title">{movie.title}</div>
        <div className="content__description">
         {movie.concepto}
        </div>
        <div className="content__subtitle">{movie.title1}</div>
        <div className="content__subdescription">
         {movie.concepto1}
        </div>
        <div className="content__subtitle">{movie.title2}</div>
        <div className="content__subdescription">
         {movie.concepto2}
        </div>
        <div className="content__subtitle">{movie.title3}</div>
        <div className="content__subdescription">
         {movie.concepto3}
        </div>

      </div>
      <button className="content__close" onClick={onClose}>
        <IconCross />
      </button>
    </div>
  </div>
);

export default Content;
