import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
//import { Link } from '@material-ui/core';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export  const ImageAvatars = ()=> {
  const classes = useStyles();

  return (
    <div className={classes.root}>
    
      <Avatar alt="Remy Sharp" src="/images/powerbi.png" className={classes.large} />
      <Avatar alt="Remy Sharp" src="/images/sqlserver.png" className={classes.large} />
      <Avatar alt="Remy Sharp" src="/images/asp.png" className={classes.large} />
      <Avatar alt="Remy Sharp" src="/images/react.png" className={classes.large} />
      <Avatar alt="Remy Sharp" src="/images/sharepoint.png" className={classes.large} />
      <Avatar alt="Remy Sharp" src="/images/sap.png" className={classes.large} />
      <Avatar alt="Remy Sharp" src="/images/oracle.png" className={classes.large} />
      <Avatar alt="Remy Sharp" src="/images/visual.png" className={classes.large} />
     
      
    </div>
  );
}

export const ImageAvatars1 = ()=> {
    const classes = useStyles();
  
    return (
      <div className={classes.root}>
      
        <Avatar alt="Remy Sharp" src="/images/knx.png" className={classes.large} />
        <Avatar alt="Remy Sharp" src="/images/Lonwork.jpg" className={classes.large} />
        <Avatar alt="Remy Sharp" src="/images/schneider.png" className={classes.large}/>
        <Avatar alt="Remy Sharp" src="/images/siemens.png" className={classes.large} />
        <Avatar alt="Remy Sharp" src="/images/festo.png" className={classes.large} />
        <Avatar alt="Remy Sharp" src="/images/arduino.png" className={classes.large} />
        
        
      </div>
    );
  }

  export const ImageAvatars2 = () => {
    const classes = useStyles();
  
    return (
      <div className={classes.root}>
      
        <Avatar alt="Remy Sharp" src="/images/barco.jpg" className={classes.large} />
        <Avatar alt="Remy Sharp" src="/images/dolby.jpg" className={classes.large} />
        <Avatar alt="Remy Sharp" src="/images/christie.png" className={classes.large} />
        <Avatar alt="Remy Sharp" src="/images/dts.jpg" className={classes.large} />
        
        
      </div>
    );
  }

  export const RedesSociales = () => {
    const classes = useStyles();
  
    return (
      <div className={classes.root}>
      
        <Avatar alt="Remy Sharp" src="/images/facebook.png" className={classes.large} />
        <Avatar alt="Remy Sharp" src="/images/instagram1.png" className={classes.large} />
        <Avatar alt="Remy Sharp" src="/images/twitter.png" className={classes.large} />
        <Avatar alt="Remy Sharp" src="/images/youtube.png" className={classes.large} />
    
      </div>
    );
  }

  export const RedesSociales1 = () => {
    const classes = useStyles();
  
    return (
      <div>
      
        <p style={{ color: 'grey' }} >
            Derechos Reservados © 2015 Copyright:
            <a href="https://www.noutech.com.mx" style={{ color: 'white' }}> NouTech.com.mx </a>
          </p>
          
            <a style={{ color: 'grey' }}> Mail:</a>
            <a style={{ color: 'white' }}>  contacto@noutech.com.mx </a>
            <p style={{ color: 'white' }} >Paseo de Cumbres</p>
            <p className='white-text' style={{ color: 'white' }}>Monterrey, Nuevo León</p>
          
                
      </div>
    );
  }

  
  export const VideoYoutube = () => {
    const classes = useStyles();
  
    return (
      <>
     <br/>
    <p>La solución que vendemos a nuestros clientes recae en la importancia de la información en nuestros días, ya que es
      considerada oro molido para las empresas. Sabemos que preparar diariamente reportes operativos o financieros
      se puede volver una tarea pesada y tediosa.</p>

      <br/>
      <p> En Noutech nos hemos dado a la tarea de diseñar una herramienta capaz de ayudarlo a generar sus reportes de mayor
    valor de manera automática; permitiéndole al usuario actualizarlos con UN SOLO CLICK, reduciendo el tiempo de
    actualización e incrementando su capacidad de análisis. A continuación se muestra un video de un ejemplo de la
    funcionalidad de un reporte de Excel con información de SAP.</p>

      <Link href='https://www.youtube.com/embed/_iiju1qDXjw' color="primary" >Visitanos en YouTube dar Click aqui!!!</Link>
      </>
    );
  }

  export const VideoYoutube1 = () => {
    const classes = useStyles();
  
    return (
      <>
     <br/>
     <p> Sabemos que preparar diariamente reportes operativos o financieros se puede volver una tarea pesada y tediosa.
        En Noutech nos hemos dado a la tarea de diseñar una herramienta capaz de ayudarlo a generar sus reportes
        de mayor valor de manera automática; permitiéndole al usuario actualizarlos con UN SOLO CLICK, reduciendo
        el tiempo de actualización e incrementando su capacidad de análisis.</p>
 

      <Link href='https://www.youtube.com/embed/BGjzxYHCvlM' color="primary" >Visitanos en YouTube dar Click aqui!!!</Link>
      </>
    );
  }

  
  export const Clientes = () => {
    const classes = useStyles();
  
    return (
      <div >
      <p> <img alt="Remy Sharp" src="/images/cemex.png" width='200'/> </p>
      <p> <img alt="Remy Sharp" src="/images/midea1.png" width='200'/> </p>
      <p> <img alt="Remy Sharp" src="/images/aliant2.jpg" width='200'/> </p>
      <p> <img alt="Remy Sharp" src="/images/riocinemas.png" width='200'/> </p>
      <p> <img alt="Remy Sharp" src="/images/capital.jpg" width='200'/> </p>
      </div>
    );
  }

