import React from 'react';

const Listas = () => {
    return (
        <div>
            <p>* Consultoría Funcional SAP.</p>
            <p>* Desarrollo de Software.</p>
            <p>* Desarrollo de Páginas Web.</p>
            <p>* Mantenimiento de Equipo de Computo.</p>
            <p>* Implementación/Auditoria de Procesos.</p>
            <p>* Administración y Supervisión de Proyectos.</p>
            <p>* Automatización Industrial.</p>
            <p>* Domótica.</p>
            <p>* Proyección Cinematográfica.</p>
            <p>* Mantenimiento de Proyectores.</p>
            <p>* Sonido Envolvente Dolby Digital.</p>
            <p>* Doremi Cinemas.</p>            
        </div>
    );
};

export default Listas;