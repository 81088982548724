import React, { Component } from 'react';
import Slider from './components/NetflixSlider'
import './App.scss'
import Listas from './components/NetflixSlider/lista';
import {Procesos, Implementacion} from './components/NetflixSlider/Procesos';
import {ImageAvatars, ImageAvatars1, ImageAvatars2, Clientes} from './components/NetflixSlider/Tecnologias';
import {VideoYoutube, VideoYoutube1, RedesSociales, RedesSociales1} from  './components/NetflixSlider/Tecnologias';
import Contactenos from './components/NetflixSlider/Contactenos';

const movies = [
  {
    id: 1,
    image: '/images/home1.jpg',
    imageBg: '/images/compu.jpg',
    title: 'Tecnología Informática',
    concepto:'Tenemos el conocimiento necesario para poder apoyar a nuestros clientes en sus planes de innovación y de tecnologías que apalanquen la optimización de sus procesos.',
    title1: 'Multimedia',
    concepto1:'Contamos con gran experiencia en sistemas de multimedia para la instalación y mantenimiento de: Audio y Proyección de imagen para la industria cinematográfica.',
    title2: 'Administracion de Proyectos',
    concepto2:'Nuestro interés es apoyar a las empresas en el control y seguimiento de las actividades que son definidas desde el inicio hasta la puesta en marcha de sus proyectos.',
    title3: 'e-duc@ción',
    concepto3:'Estamos completamente convencidos que la mejor inversión es la educación como proceso de mejora continua; por tal motivo, ofrecemos cursos a nuestros clientes, para poder obtener mejores resultados en la ejecución de sus procesos.'
 
  },
  {
    id: 2,
    image: '/images/nosotros.jpg',
    imageBg: '/images/nosotros4.jpg',
    title: 'Nosotros',
    concepto:'Somos una empresa de Consultoría en Tecnologías de Información y Multimedia, dedicada a proveer soluciones integrales que sean apropiadas a las necesidades de nuestros clientes. Esto es posible gracias a profesionales con amplia experiencia de más de 20 años en empresas nacionales y multinacionales.',
    title1: 'Nuestra Misión',
    concepto1:'Crear sinergia con nuestros clientes para darles pronta solución a sus necesidades tecnológicas, con la mejor calidad, ética profesional e innovación del mercado.',
    title2: 'Nuestra Visión',
    concepto2:'Consolidarnos como una empresa lider en consultoria tecnológica que ofrezca soluciones que generen valor a través de la confianza, innovación, y adaptación para lograr la total satisfacción de nuestros clientes.',
    title3: 'Nuestras Competencias',
    concepto3:'Contamos con personal de gran experiencia y capacidad en obtener resultados, para satisfacer a nuestros clientes en sus necesidades tecnológicas.'
 
  
  },
  {
    id: 3,
    image: '/images/servicios.jpg',
    imageBg: '/images/servicios3.jpg',
    title: 'Nuestros Principales Servicios',
    concepto: <Listas/>
      
     },
  {
    id: 4,
    image: '/images/procesos.jpg',
    imageBg: '/images/procesos1.jpg',
    title: 'Modelo de Gestión de Servicios en las Tecnologías de Información.',
    concepto: <Procesos/>,
   
  },
  {
    id: 5,
    image: '/images/tecno.jpg',
    imageBg: '/images/drone.jpg',
    title: 'Tecnologías en la que nos Especializamos',
    concepto: 'Estas son algunas de las tecnologías en la que nos especializamos y algunas de ellas contamos con certificaciones.',
    title1:'Software',
    concepto1:<ImageAvatars/>,
    title2:'Automatización',
    concepto2:<ImageAvatars1/>,
    title3:'Multimedia',
    concepto3:<ImageAvatars2/>,

  },
  {
    id: 10,
    image: '/images/mantto.jpg',
    url: '/images/dashboard.mp4',
    title: 'Mantenimiento de Hardware',
    title1: 'Cansad@s de que tu equipo no funcione adecuadamente?',
    concepto1: 'Cuentanos que sucede y te hacemos un diagnóstico gratis, ahorra tiempo y dinero, dejando tus equipos de computo en manos de profesionales, todos los servicios estan garantizados. ',
   },
   {
     id: 11,
   image: '/images/dass.jpg',
   url: '/images/dashboards3.mp4',
   title: 'Dashboards.com.mx',
   title1: 'Objetivo',
   concepto1: 'Integrar la WebApp de dashboards.com.mx con la información financiera y operativa que es generada por el cliente de manera diaria, semanal o de cierre mensual, dando como resultado la actualización y procesamiento de información que se desplegara en los principales reportes y KPI’s, para la gestión y análisis de la alta dirección de la Empresa.',
  },
  {
    id: 6,
    image: '/images/app5.jpg',
    url: 'https://www.youtube.com/watch?v=_iiju1qDXjw&feature=emb_imp_woyt',
    title: 'Extraer Datos de SAP a Excel',
    concepto: <VideoYoutube/>
   },

   {
    id: 7,
    image: '/images/app7.jpg',
    url: 'https://www.youtube.com/embed/BGjzxYHCvlM',
    title: 'Extraer Datos de SAP a Excel',
    concepto: <VideoYoutube1/>
   },

   {
    id: 8,
    image: '/images/clientes.jpg',
    url: '/images/clientes1.jpg',
    title: 'Nuestros Clientes',
    concepto: <Clientes/>
   },

   {
    id: 9,
    image: '/images/contactanos.png',
    url: '/images/contactanos1.jpg',
    title: 'Contactanos',
    concepto: 'Contactenos por medio de Correo Electrónico y nosotros nos pondremos en contacto.',
    title1:'Nuestras Redes Sociales.',
    concepto1:<RedesSociales/>,
    concepto2: <RedesSociales1/>
   },
  

];

class App extends Component {
  render() {
    return (
      <div className="app">
         <h3 style={{color:'white'}}>NouTech S.A de C.V</h3>
        <Slider>
         
          {movies.map(movie => (
            <Slider.Item movie={movie} key={movie.id}>NouTech</Slider.Item>
          ))}
        </Slider>
      </div>
    );
  }
}

export default App;
