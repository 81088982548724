import React from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import GitHubIcon from '@material-ui/icons/GitHub';

import YoutubeIcon from '@material-ui/icons/YouTube';

import Link from '@material-ui/core/Link';
import ListItemText from '@material-ui/core/ListItemText';

function Contactenos(props) {
    return (
        <div>
            <footer class="page-footer mdb-color text-center text-md-left pt-4 mt-4" style={{ backgroundColor: '#3C4565' }}  >
        <div class="text-center mb-3"   >
          <FacebookIcon style={{ fontSize: 30, color: 'white' }} />
          <TwitterIcon style={{ fontSize: 30, color: 'white' }} />
          <InstagramIcon style={{ fontSize: 30, color: 'white' }} />
          <GitHubIcon style={{ fontSize: 30, color: 'white' }} />
          <YoutubeIcon style={{ fontSize: 30, color: 'white' }} />

        </div>
        <div class="footer-copyright text-center py-3" style={{ backgroundColor: '#303750' }}>
          <div class="container-fluid" style={{ color: 'grey' }} >
            Derechos Reservados © 2015 Copyright:
            <a href="https://www.noutech.com.mx" style={{ color: 'white' }}> NouTech.com.mx </a>
          </div>
          <div className="container-fluid">
            <a style={{ color: 'grey' }}> Mail:</a>
            <a style={{ color: 'white' }}><strong>contacto@noutech.com.mx</strong> </a>
            <div style={{ color: 'white' }} >Paseo de Cumbres</div>
            <div className='white-text' style={{ color: 'white' }}>Monterrey, Nuevo León</div>
          
              <u >   <Link style={{ color: 'white' }} to="/Privacidad"><ListItemText primary="Aviso de Privacidad" /></Link></u>
           
          </div>
        </div>
      </footer>
        </div>
    );
}

export default Contactenos;